.loader {
    width: 107px;         
    padding: 12px;     
    background: #4600f2;
    
    aspect-ratio: 1;
    border-radius: 50%;
    --_m: 
      conic-gradient(#0000,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    box-sizing: border-box;
    animation: load 1s linear infinite;
    margin-left: 20px;
  }
  
  @keyframes load {
    to{transform: rotate(1turn)}
  }

  .loader-component p{
    font-size: 15px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    font-family:Arial, Helvetica, sans-serif
  }


.loader-component{
    height: 100vh;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
}

.spinner-container {
  /* width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none; */
  height: 100vh;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  overflow: hidden!important;
}

.spinner div {
  position: absolute;
  width: 24px;
  height: 24px;
  border: 4px solid #4600f2;
  border-top-color: transparent;
  border-radius: 50%;
}

.spinner div {
  animation: spinner 1s linear infinite;
}


.spinner {
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.spinner div {
  box-sizing: content-box;
}
@keyframes spinner {
  0% {
      transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
      transform: translate(-50%, -50%) rotate(360deg);
  }
}

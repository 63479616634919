
.slickListContainer {
    position: relative;
    text-align: center;
    color: white;
height: 100vh;
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    .slickListContainerbeforeAfter{
    position: relative;
    text-align: center;
    color: white;
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    .slickListContainerDisable {
    position: relative;
    text-align: center;
    color: white;
    height: 100%;
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    margin-bottom: 50px;
}
.slickListContainerMobileView{
        position: relative;
        text-align: center;
        color: white;
        height: 30vh;
        margin: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
}
.slickListContainerMobileDisable{
            position: relative;
            text-align: center;
            color: white;
            height: 30vh;
            margin: auto;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            pointer-events: none;
}
.slickListContainerbeforeAfter{
    position: relative;
    text-align: center;
    color: white;
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    }
.slickListContainerDisable {
    position: relative;
    text-align: center;
    color: white;
    height: 100vh;
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    margin-bottom: 50px;
}

.slickListContainerMobileView{
        position: relative;
        text-align: center;
        color: white;
        height: 30vh;
        margin: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
}
.slickListContainerMobileDisable{
            position: relative;
            text-align: center;
            color: white;
            height: 30vh;
            margin: auto;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            pointer-events: none;
}

.hidden{
    display: none;
}

.viewContainer{
    position: relative;
    height: 100%;
    width: auto;
    max-height: 100vh;
    display: flex;
    justify-content: center;
}


.viewContainer img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.zoomTools {
    /* position: absolute;
    bottom: 2.5%;
    right: 1%; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /* background-color: rgba(0, 0, 0, 0.6); */
    background-color: transparent;
    gap: 0.5rem;
    z-index: 100;
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;
    font-family: 'Inter', sans-serif;
}   

.zoomTools button {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 50%;
    border-width: 1px;
    border-color: white;
    display: flex;
    justify-items: center;
    place-items: center;
}

.zoomTools button img {
    width: 1.5rem !important;
    height: 1.5rem !important;
}

.zoomTools span {
    border-radius: 1.875rem;
    border-width: 1px;
    padding: 0.25rem 0.75rem;
    background-color: rgba(0, 0, 0, 0.4);
    font-weight: 500;
    font-size: 0.875rem;
    display: flex;
    justify-items: center;
    place-items: center;
}

.zoomedImageContainer{
    height: 100% !important;
    width: 100% !important;
}

.zoomedImageContainer img{
    height: 100vh !important;
    width: 100% !important;
    object-fit: contain;
}
.helperTextContainerMobile{
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 0.375rem 1rem; 
    border-radius: 1.5625rem; 
    transition: all ease-in-out 1s;
    font-size: 1rem;  
    line-height: 1.5rem; 
    font-weight: 500;   
    flex-wrap: nowrap;
    user-select: none;
    pointer-events: none;
    font-family: 'Inter', sans-serif;
    gap: 0.75rem;
}
.helperTextContainerMobile img{
    width: 32px !important; 
    height: 32px !important;
}
.helperTextContainer {
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 0.375rem 1rem; 
    border-radius: 1.5625rem; 
    transition: all ease-in-out 1s;
    font-size: 1rem; 
    line-height: 1.5rem; 
    font-weight: 500;   
    flex-wrap: nowrap;
    user-select: none;
    pointer-events: none;
    font-family: 'Inter', sans-serif;
    gap: 0.75rem;
    min-width: 220px;
}


.helperTextContainer img{
    width: 32px !important; 
    height: 32px !important;
}

.hideHelperTextContainer {
    opacity: 0;
}

.helpTextContainerTextMobile{
    display: none;
}

.blackOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 90;
    pointer-events: none; 
    transition: all ease-in-out 1s;
}

.blackOverlayHidden{
    opacity: 0;
}

.helperTextContainerMobile h3{
    font-size: 16px;
    margin: 0;
    padding-top: 4px;
}
.helperTextContainerMobile p{
    font-size: 14px;
    margin: 0;
}
.helperTextContainer h3{
    font-size: 16px;
    margin: 0;
    padding-top: 4px;
}
.helperTextContainer p{
    font-size: 14px;
    margin: 0;
}


.slickListContainerItemcentered {
    position: absolute;
    top: 10%;
    left: 10%;
    z-index: 10;
    cursor: pointer;
    width: 22px !important;
    height: 22px !important;
    animation: fadein 0.2s;
    animation-timing-function: linear;
  }
  .slickListContainerItemcentered-span {
    position: absolute;
    z-index: 1;
    width: 22px !important;
    height: 22px !important;
    animation: fadein 0.2s;
    animation-timing-function: linear;
    animation: ripple 1.5s ease-out infinite;
    background-color: #5465FF99;
    border-radius: 50%;
  }
  .slickListContainerItemcentered-span-hotspot{
    position: absolute;
    z-index: 1;
    width: 22px !important;
    height: 22px !important;
    animation: fadein 0.2s;
    animation-timing-function: linear;
    animation: ripple 1.5s ease-out infinite;
    background-color: #FFEBB066;
    border-radius: 50%;
}
.front {
    cursor: grab;
}

/* ------HOTSPOT MODAL------- */
.modal-content{
    text-align: center;
    align-items: unset;
    border-radius: 8px !important;
}

.view-button {
    width: 80px;
    height: 30px;
    background: #FF7700;
    border-radius: 4px;
}

.view-text {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
}


.please-wait{
    font-family: 'Plus Jakarta Display' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 31px !important;
    text-align: center !important;
    color: #1F1F22 !important;
}

@keyframes openmodel {
    from {top: 40%;}
    to {top: 50%;}
  }

/*RIPPLE*/


@keyframes ripple {
    from {
      opacity: 1;
      transform: scale(0);
    }
    to {
      opacity: 0;
      transform: scale(3);
    }
  }


.hotspot-toggle-view{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    background: white;
    padding: 5px;
    border-radius: 10px;
    width: 180px;
    height: 38px;
}

.hotspot-toggle-view span{
    color: black;
    font-family: 'Poppins';
    font-weight: 600;
}
.hotspot-toggle-view .dot{
    width: 15px;
    height: 15px;
    background: #df6e0e;
    border-radius: 50%;
}

.view-360-container {
    overflow: hidden !important;
}
.view-360-container-mobile {
    height: 100%;
    overflow: hidden !important;
    position: relative;
}

.custom-model-2 {
    width: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 15;
    border-radius: 8px;
    padding: 0px;
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    z-index: 11;
    /* animation: openmodel 0.2s;
    animation-timing-function: linear; */
    text-overflow: ellipsis
}

/* .custom-model-2 span{
    margin: 5px 5px 0 0;
    display: block;
    text-align: end;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
} */

.custom-model-2 img {
    width: auto;
    height: 250px;
    object-fit: contain;
}

.custom-model-2 h4 {
    font-weight: 800;
}

.custom-model-2 i {
    font-size: 16px;
}

.inactive-view-btn {
    background: #c1bdbd;
}

.custom-model-2 .card-custom{
    border-radius: 10px;
    width: 409px;
    padding: 0;
}

.custom-model-2 .card-text-custom, .custom-model-2 .card-title-custom{
    max-height: 3rem;
    overflow-y: hidden;
}

.slick-slider-custom{
    width: 100%;
    height: 85%;
    display: flex;
}

.slick-contents-container {
    display: flex;
    height: 100%;
    justify-content: space-evenly;
    flex-direction: column;
    /* background: #ECECEC; */
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 10px;
    width: 100%;
    position: relative;
}

.slick-contents-container img {
    width: auto;
    /* height: 168px; */
    margin: auto;
    object-fit: contain;
}

.card-container-custom {
    margin: auto;
    height: auto;
    width:100%;
}

.card-body-custom{
    width: 100%;
}

.card-custom-close {
    font-size: 22px !important;
    text-align: end;
    display: block;
    margin: 5px 5px 0 0;
    cursor: pointer;
}


.custom-card-body{
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 33px;
}
.custom-card-body h4{
    font-weight: 800;
}
.custom-card-body span{
    display: block;
    text-align: end;
}


.fallback-ui-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    margin: auto;
}

.fallback-ui-container span{
    font-weight: 700;
    word-spacing: 10px;
    font-size: x-large;
}
.DentIcon__container {
    height: auto;
    justify-content: center;
    position: absolute;
    width: auto;
    /* display:none;   */
    z-index: 10;
    cursor: pointer;
    animation: fadein 0.2s;
    animation-timing-function: linear;
    /* left: calc(74% - 2.5%);
    top: calc(80.66% - 3%); */
}

.DentIcon__container .DentIcon__wrap {
    z-index: 1;
    width: 35px;
    height: 35px;
}

.DentIcon__wrap {
    z-index: 1;
    width: 35px;
    height: 35px;
}
.def {
    z-index: 1;
}
.DentIcon__label.DentIcon__open {
    /* transform: scale(1);     */
}
.DentIcon__label {
    background-color: #fff;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);
    left: 0;
    margin-left: 35px;
    margin-top: -22px;
    position: absolute;
    top: 0;
    transform: scale(0);
    transform-origin: 0 50%;
    transition: transform .35s cubic-bezier(.86, 0, .07, 1);
    white-space: nowrap;
    z-index: 2;
    font-family: Inter!important;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: #000000CC;
    padding: 3.39px 10.16px;
    border-radius: 16.93px;
    gap: 8.47px


}
.DentIcon__container .DentIcon__wrap path {
    stroke-dasharray: 200;
    stroke-dashoffset: 200;
    animation: DentIcon__dashOffset .25s cubic-bezier(.86, 0, .07, 1) 0s 1 normal forwards running;
}

@keyframes DentIcon__dashOffset {
    to {
      stroke-dashoffset: 0;
    }
  } 
  .exterior-interior-toggle{
    /* position: absolute;
    bottom: 2.5%;
    left: 1%; */
    margin-left: 13px;
    
    padding: 8px 12px 8px 8px;
}
.exterior-interior-toggle-mobile-view{
    position: absolute;
    margin-left: 10px;
    top: 14px;
}
.before-after-toggle-view{
    position: absolute;
    bottom: 40px;
    left: 45%;
}
.before-after-tab{
    position: absolute;
    bottom: 40px;
    /* left: 42%; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.before-after-toggle-view{
    position: absolute;
    bottom: 40px;
    left: 45%;
}
.before-after-tab{
    position: absolute;
    bottom: 40px;
    /* left: 42%; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.exterior-interior-tabs-mobile-view{
    padding: 4px;
    display: flex;
    border-color: rgba(0, 0, 0, 0.1);
    border-width: 1px;
    border-radius: 0.5rem;
    width: fit-content;
    --tw-shadow: 0px 4px 8px rgba(0,0,0,0.32);
    --tw-shadow-colored: 0px 4px 8px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    position: relative;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    list-style: none;
    margin: 0;
}
.before-after-tab-view{
    display: flex;
    border-color: rgba(0, 0, 0, 0.1);
    border-width: 1px;
    border-radius: 0.5rem;
    width: fit-content;
    --tw-shadow: 0px 4px 8px rgba(0,0,0,0.32);
    --tw-shadow-colored: 0px 4px 8px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    position: relative;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    list-style: none;
    padding: 12px;
    margin: 0;
}
.exterior-interior-tabs{
    padding: 4px;
    display: flex;
    /* border-color: rgba(0, 0, 0, 0.1); */
    /* border-width: 1px; */
    /* border-radius: 0.5rem; */
    width: fit-content;
    --tw-shadow: 0px 4px 8px rgba(0,0,0,0.32);
    --tw-shadow-colored: 0px 4px 8px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    position: relative;
    --tw-bg-opacity: 1;
    background-color: transparent;
    list-style: none;
    margin: 0;
    border: 1px solid #FFFFFF1A;
    border-radius: 8px;
    /* bor */
}

/* .ext-tabs-bg { */
    /* @apply absolute bg-blue-light rounded-md left-1 top-1 h-[calc(100%-8px)] w-[calc(50%-4px)] z-0; */
    /* position: absolute; --tw-bg-opacity: 1;
    background-color: #fff;
    border-radius: 0.375rem;
    left: 0.19rem;
    top: 0.19rem;
    height: calc(100% - 6px);
    width: calc(50% - 3px);
    z-index: 0;
  } */
  
/*   
  .ext-tabs li.active~.ext-tabs-bg {
    transition: transform .3s cubic-bezier(.645, .045, .355, 1);
    
  }
  .ext-tabs li.active~.ext-tabs-bg{
    transform: translateX(0%);
  }
  .ext-tabs li:nth-child(1).active~.ext-tabs-bg{
    transform: translateX(100%);
  } */
  .ext-tabs-bg{
    padding: 5px 12px ;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .exterior-interior-tabs li{
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
    z-index: 1;
    color: #FFFFFF99;
    transition: .2s all ease-in-out;
  }
  .exterior-interior-tabs li.active{
    color: #000000CC!important;

  }
  .exterior-interior-tabs li.active .ext-tabs-bg{
    background-color: #FFFFFF;
  }
/* .exterior-interior-tabs li.active~.ext-tabs-bg {
    transition: transform .3s cubic-bezier(.645, .045, .355, 1);
    
  }
  .exterior-interior-tabs li.active~.ext-tabs-bg{
    transform: translateX(0%);
  }
  .exterior-interior-tabs li:nth-child(2).active~.ext-tabs-bg{
    transform: translateX(100%);
  } */
  .view-360{
    margin: 0px!important;
    padding: 0px!important;
  }
  .view-360-mobile{
    margin: 0px!important;
    padding: 0px!important;
    /* height: 100vh; */
    background-color: #EAEAEA;
  }
  .hotspot-info-tag-left {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 0px 6px 10px;
      width: 100%;
      height: 28px;
      border-radius: 8px;
      color: #FFFFFFCC;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      
  }
.hotspot-info-tag {
    position: absolute;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    bottom: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.32);
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    padding: 0px 6px 0px 12px;
    text-align: center;
    left: 20px;
    color: #000000;
}

.interior-exterior-container{
    position: absolute;
    top: 84%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10 ;
    /* left: 45%; */
    width: 100%;
    
}
.grid-view .interior-exterior-container{
    top: 76%;
}
.interior-exterior-container-sml-screen{
    
    position: absolute;
    top: 76%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10 ;
    /* left: 45%; */
    width: 100%;
}


.interior-exterior-container-panel{
    /* padding: 8px 12px 8px 8px; */
    border-radius: 12px;
    gap: 12px;
    /* top: 87%;
    left: 37%; */
    z-index: 10;
    position: relative;
    background-color: #00000099;
    display: flex;
    align-items: center;
    border: 1px solid #FFFFFF33;
}


.Download-container-panel{
    border-radius: 12px;
    position: absolute;
    right: -180px;
    height: 3.2rem;
    padding: 0.3rem;
    gap: 12px;
    margin-left: 2rem;
    padding-right: 0.4rem;
    /* top: 87%;
    left: 37%; */
    z-index: 10;
    background-color: #00000099;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #FFFFFF33;
}

@media (max-width: 840px) {
    .Download-container-panel {
        display: none;
    }
}



/* .customSwitchTrack */
.MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track,.Mui-checked+.MuiSwitch-track{
    background: transparent!important;
    /* color: transparent!important; */
}
.line{
    width: 1px;
    height: 25px;
    background-color: #FFFFFF29;

}
/* input[type='range'] {
    background-color: #9a905d;
  } */
  input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 5px;
    padding: 0;
    border-radius: 2px;
    outline: none;
    cursor: pointer;
  }
  
  
  /*Chrome thumb*/
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-radius: 20px;
    /*16x16px adjusted to be same as 14x14px on moz*/
    height: 16px;
    width: 16px;
    background: #FFFFFF;
    border: 1px solid #c5c5c5;
  }





  
.ext-tabs-bg-mobile-view {
    padding: 5px 12px ;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .exterior-interior-tabs-mobile-view li.active .ext-tabs-bg-mobile-view{
    background-color: #4600F2;
  }


  .before-after-tabs-bg{

    /* @apply absolute bg-blue-light rounded-md left-1 top-1 h-[calc(100%-8px)] w-[calc(50%-4px)] z-0; */
    position: absolute; --tw-bg-opacity: 1;
    background-color: #4600F214!important;
    border-radius: 0.375rem;
    left: 0.40rem;
    top: 0.40rem;
    height: calc(100% - 12px);
    width: calc(50% - 8px);
    z-index: 0;
  }
  .ext-tabs li.active~.before-after-tabs-bg {
    transition: transform .3s cubic-bezier(.645, .045, .355, 1);
    
  }
  .ext-tabs li.active~.before-after-tabs-bg{
    transform: translateX(0%);
  }
  .ext-tabs li:nth-child(1).active~.before-after-tabs-bg{
    transform: translateX(100%);
  }
  /* //fds */
  /* .ext-tabs li.active~.ext-tabs-bg-mobile-view {
    transition: transform .3s cubic-bezier(.645, .045, .355, 1);
    
  }
  .ext-tabs li.active~.ext-tabs-bg-mobile-view{
    transform: translateX(0%);
  }
  .ext-tabs li:nth-child(1).active~.ext-tabs-bg-mobile-view{
    transform: translateX(100%);
  } */
  .before-after-tab-view li{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    z-index: 1;
    color: rgba(0, 0, 0, 0.6);
    padding: 3px 15px;
    transition: .2s all ease-in-out;
    min-width: 190px;
  }

  .exterior-interior-tabs-mobile-view li{
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
    z-index: 1;
    color: rgba(0, 0, 0, 0.6);
    transition: .2s all ease-in-out;
  }
  .exterior-interior-tabs-mobile-view li.active{
    color: rgb(255 255 255)!important;
  }
  .exterior-interior-tabs-mobile-view li.active .ext-tabs-bg-mobile-view{
    background-color: #4600F2;
  }
  .selected-name{
    margin-top: 3px;
}
   /* fdsjfhks */
   .before-after-tab-view li.active{
    color: #4600F2!important;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: ; */
  }
  .tabs{
    display: flex;
    align-items: center;
    justify-content: center;
  }
.before-after-tab-view li.active~.before-after-tabs-bg {
    transition: transform .3s cubic-bezier(.645, .045, .355, 1);
    
  }
  .before-after-tab-view li.active~.before-after-tabs-bg{
    transform: translateX(0%);
  }
  .before-after-tab-view li:nth-child(2).active~.before-after-tabs-bg{
    transform: translateX(100%);
  }

  
  .interior-exterior-container-mobile-view{
    width: 100%;
    position: relative;
    /* margin-left: 24px;
    top: 30px; */
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hotspot-info-tag-left-mobile-view{
    height: 30px!important;
    border-radius: 5px;
    margin-top: 50px;
    margin-left: 14px;
    width: max-content;
    color: rgba(29, 17, 17, 0.8);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
        /* 142.857% */
}
.container-popup{
    position: absolute;
    /* width: 300px!important; */
    z-index: 100;
    right: 16px;
    top: 16px;
    /* background-color: rgba(77, 77, 77, 0.8); */
    border-radius: 6px;
}
.card-custom-close{
    position: absolute;
    right: 0px!important;
    z-index: 9999;
    width: 20px!important;
    height: 20px!important;
    top: 0px!important;
}
.info-container{
    /* background-color: transparent; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.60);
    top: 0px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    /* border-radius: 6px!important; */
}
.icon-left-right{
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon-left-right i{
    /* padding: 4px; */ 
}
.icon{
    width: 25px;
    height: 25px;
    /* background-color: #df6e0e; */
    margin-right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    /* border: 1px solid rgba(255, 255, 255, 0.20); */
}
.info-container p{
    margin: 0px!important;
    color: #FFF;
    
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 9px 8px!important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.info-container svg{
    margin-right: 5px;
    z-index: 100;
}
.info-container span{
    color: rgba(255, 255, 255, 0.60);
    
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
    margin-left: 10px;
    line-height: 10px;
    margin-top: 1px;
}
.image-modal-container{
    background-color: transparent;
    width: 100%!important;
    /* height: 170px!important; */
    object-fit: contain!important;
    object-position: center;
    border-radius: 6px!important;
}
.header-slider-container{
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    /* height: 170px; */
    position: relative;
}
.active-hotspot{
    position: absolute;
    width : 40px;
    height : 40px;
}
.circle {
    r: 19;
  }
  .mobile-view-slider{
    width: 100%;
    display: none;
  }

  .DentListModalDesktop__canvas {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.image-crousel{
    z-index: 5;
}
.active-line-hotspot{
    pointer-events: none!important;
    position: absolute;
    left: 0;
    right: 0;
}
.scroll-container{
    padding: 0px;
    margin: 0px;
    position: relative;

    margin-left: 7px;
    margin-right: 7px;
    /* min-height: 220px; */
    /* max-height: 220px; */
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}
.slick-slide {
    transition: transform 0.5s ease;
  }
.scroll-container-active{
    transform: scale(1.05);
    padding: 0px;
    margin: 0px;
    position: relative;
    transition: transform 0.5s ease;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: 7px;
    margin-right: 7px;
    /* min-height: 220px; */
    /* max-height: 220px; */
    border-radius: 8px;
}
.scroll-container-active img{
    /* min-height: 200px;  */
    /* /* max-height: 200px; */
    /* object-fit: fill; */
}
.scroll-container img{
    /* min-height: 200px; */
    /* max-height: 200px; */
    /* object-fit: fill; */
}
.header-icon-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cross-icon{
    width: 24px;
height: 24px;
/* padding: 5.25px; */
    border-radius: 150px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: rgba(0, 0, 0, 0.60);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3px;
}
.image-count {
    color: #000;

    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-left: 3px;
    margin-right: 3px;
}
.left-right-count-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.left-right-count-container svg{
    margin-right: 5px!important;
}
.mobile-view-text{
    color: #000;
    
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        padding: 15px;
}

.yourModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    outline: none;
    overflow-x: hidden;
    overflow-y: hidden;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .yourModalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.80);
    transition: opacity 0.2s;
  }

  .yourModalContent {
    position: relative;
    display: flex;
    /* width: auto; */
    align-items: center;
    padding: 0;
  }
  .yourModalContent svg{
    margin-left: 20px;
    margin-right: 20px;
  }

  .image-modal img{
    object-fit: contain;
    border-radius: 16px!important;
  }
  .image-modal{
    border-radius: 16px!important;
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.10);

    background: #3D3D3D;
  }
  .image-modal .info-container{
    padding: 16px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  .image-modal .info-container p{
    font-size: 20px!important;
    margin-left: 10px!important;
  }
  .image-modal .info-container p span{
    font-size: 20px!important;
  }
  .image-modal .info-container svg{
    margin-right: 10px;
  }
  .modal-image-viewer-hidden{
    visibility: hidden;
  }

  .slider-container{
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 0.75rem/* 12px */;
  }
  .image-slider{
    /* display: grid; */
    position: sticky;
    top: 1.5vh;
    width: 100%;
  }
  .image-slider-header{
    display: flex;
    width: 100%;
    gap: 0.75rem;
    row-gap: 0px;
  }
  .grid-view{
    width: 50%;
    text-align: center;
    transition: all .2s linear;
    position: relative;
    height: 100%;
  }
  .list-view{
     transition: all .2s linear;
  }
  .min-list-view{
    text-align: center;
    width: 25%;
    transition: all .2s linear;
  }

  .max-list-view{
    text-align: center;
    width: 75%;
    transition: all .2s linear;
    position: relative;
    text-align: center;
  }
  .beforeImg{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .grid-view .before-image .beforeImg{
    width: 100%;
    border-radius: 16px;
    height: 100%;
  }
  .renderImage{
    border-radius: 16px;
  }
  .list-view .beforeImg img,
  .list-view .afterImg img{
    /* transition: all .1s ease-in-out; */
    height: auto;
    width: 100%;
    border-radius: 1rem;
    object-fit: contain;
}
  .before-after-text{
    border-radius: 20px;
    background-color: #0000000D;
    padding-top: 0.25rem/* 4px */;
    padding-bottom: 0.25rem/* 4px */;
    padding-left: 1.5rem/* 24px */;
    padding-right: 1.5rem/* 24px */;
    font-size: 0.75rem/* 12px */;
    line-height: 1rem/* 16px */;
    font-weight: 500;
    line-height: 15px;
    --tw-text-opacity: 1;
    color: #121212;
  }
  .images-middle-container{
    overflow-x: hidden;
    max-height: 85vh;
    display: grid;
    width: 100%;
    gap: 0.75rem/* 12px */;
  }
  .list-grid-view{
    position: relative;
    display: flex;
    width: 100%;
    gap: 0.75rem/* 12px */;
    row-gap: 0px;
    margin-bottom: 5rem/* 80px */;
  }


  .before-image,
  .after-image {
    position: relative;
    border-radius: 16px;
    border-color: rgba(0, 0, 0, 0.1);
    border-width: 1px;
}

.grid-view .before-image{

    position: relative;
    border-radius: 16px;
    border-color: rgba(0, 0, 0, 0.1);
    border-width: 1px;
    height: 100%;
}
.grid-view .beforeImg,.afterImg img{
    width: 100%;
    margin: 0px;
    object-fit: cover;
    border-radius: 16px;

}
.min-list-view .beforeImg img{
    height: auto;
    width: 100%;
    border-radius: 1rem/* 8px */;
    object-fit: contain;
}
.max-list-view .afterImg img{
    height: auto;
    width: 100%;
    object-fit: contain;
}
.after-image{
    width: 100%;
    border: 1.5px solid #0000001A;
    border-radius: 16px;
    /* overflow: hidden; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.video-container{
    width: 100%;
    border-radius: 16px;
    height: 100%;
    /* min-height: 250px; */
    /* margin: auto; */
}
.grid-view .before-image .beforeImg .video-container{
    width: 100%;
    border-radius: 16px;
    height: 100%;
}
.mobile-video-uploader{
    margin-top: 10px;
}
.before-after-svg{
    margin-right: 10px;
}
.mobile-input-output-container{
    width: 100%;
}
.before-after-cont{
    height: 70vh;
    position: relative;
}
.frame_video{
    width: 100%;
    height: 100%;
}
.dropdown-container{
    width: 100%;
    position: absolute;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 12px;
}
.header-container{
    /* width: 200px; */
    font-size: 12px;
    font-family: 'Inter';
    font-weight: '600';
    line-height: 21px;
    word-wrap: 'break-word';
    /* padding:8px 10px; */
    padding: 8px 0px 8px 10px;
    background-color: #FFFFFF;
    border-radius: 8px;
    color: #000000CC;
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    /* min-width: 80px; */
}
.dropdown-url-name{
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    /* border :  1px solid rgba(0, 0, 0, 0.1); */
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    position: relative;
    margin-top: 20px;
    cursor: pointer;
    width: max-content;
    min-width: 100px;
}
.dropdown-url-heading{
    padding: 10px 16px;
    border-bottom: 1px solid lightgray;
    display: flex;
    text-align: left;
    color: '#344054';
    font-size: 14;
    font-weight: 500;
    line-height: 20px;
    /* word-wrap: 'break-word' */
}
.dropdown-url-heading:last-child{
    padding: 10px 16px;
    border-bottom: none;
}
.dropdown-icon-noactive{
    /* transform: rotate(-90deg); */
    cursor: pointer;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    color: transparent;
    /* margin-left: 2px; */
}
.dropdown-icon-active{
    transform: rotate(-90deg);
    cursor: pointer;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    color: transparent;
    /* margin-left: 2px; */
}
.show-interior-frame{
    /* display: block; */
    visibility: visible;
    width: 100%;
    height: 100%;
}
.hide-interior-frame{
    /* display: none; */
    visibility: hidden;
    width: 100%;
    height: 100%;
}
/* .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{} */
/****Media queries******/
@media (min-width: 1440px){
    .slickListContainerItemcentered, .slickListContainerItemcentered-span{
        width: 24px !important;
        height: 24px !important;
    }
    .slickListContainerItemcentered, .slickListContainerItemcentered-span-hotspot{
        width: 24px !important;
        height: 24px !important;
    }
    .custom-card-body{
        font-size: 20px;
    }
    .custom-card-body h4{
        font-size: 20px;
        font-weight: 800;
    }
    .hotspot-toggle-view{
        width: 200px;
        height: 45px;
        font-size: 18px;
    }
    
    
}
@media (min-width: 1640px){
    .slickListContainerItemcentered, .slickListContainerItemcentered-span{
        width: 22px !important;
        height: 22px !important;
    }
    .slickListContainerItemcentered, .slickListContainerItemcentered-span-hotspot{
        width: 22px !important;
        height: 22px !important;
    }
    .DentIcon__wrap{     
        width: 40px!important;
        height: 44px!important;
    }
    .custom-card-body{
        font-size: 23px;
    }
    .custom-card-body h4{
        font-size: 23px;
        font-weight: 800;
    }
    .hotspot-toggle-view{
        width: 220px;
        height: 48px;
        font-size: 20px;
    }
    .hotspot-toggle-view .dot{
        width: 20px;
        height: 20px;
    }
}
@media (min-width: 2186px){
    .slickListContainerItemcentered, .slickListContainerItemcentered-span{
        width: 22px !important;
        height: 22px !important;
    }
    .slickListContainerItemcentered, .slickListContainerItemcentered-span-hotspot{
        width: 22px !important;
        height: 22px !important;
    }
    .custom-card-body{
        font-size: 25px;
    }
    .custom-card-body h4{
        font-size: 25px;
        font-weight: 800;
    }
    .hotspot-toggle-view{
        width: 220px;
        height: 48px;
        font-size: 20px;
    }
    .hotspot-toggle-view span{
        width: 55px;
    }
    .hotspot-toggle-view .dot{
        width: 25px;
        height: 25px;
    }
}

@media screen and (min-device-width:1440px) and (max-device-width: 1640px){
    .DentIcon__wrap{     
        width: 40px!important;
        height: 40px!important;
    }
}
@media screen and (min-device-width:1640px) and (max-device-width: 2186px){

    .DentIcon__wrap{     
        width: 40px!important;
        height: 40px!important;
    }
    .slickListContainerItemcentered, .slickListContainerItemcentered-span{
        width: 25px !important;
        height: 25px !important;
    }
    
    .slickListContainerItemcentered, .slickListContainerItemcentered-span-hotspot{
        width: 25px !important;
        height: 25px !important;
    }
    
}

@media screen and (min-device-width:440px) and (max-device-width: 886px){
    .slickListContainerItemcentered, .slickListContainerItemcentered-span{
        width: 15px !important;
        height: 15px !important;
    }
    .slickListContainerItemcentered, .slickListContainerItemcentered-span-hotspot{
        width: 15px !important;
        height: 15px !important;
    }
    .DentIcon__wrap{     
        width: 25px!important;
        height: 25px!important;
    }
    .DentIcon__label{
        margin-left: 20px;
        margin-top: -12px;    
        padding: 2px 4px;
        font-size: 8px;
        line-height: 10px;
    }
    .interior-exterior-container{
        top: 90%;
    }
    .hotspot-info-tag-left {
        padding: 6px 10px 6px 6px!important;
        width: 100%;
        height: 12px!important;
        padding: 6px 10px;
        border-radius: 5px;
        color: #FFFFFFCC;
        font-weight: 500;
        font-size: 10px!important;
        line-height: 12px!important;
        letter-spacing: 0em;
        text-align: center;
    } 
        .exterior-interior-tabs li{
        font-weight: 500;
        cursor: pointer;
        z-index: 1;
        color: #FFFFFF99;
        /* padding: 6px 13px; */
        transition: .2s all ease-in-out;
      }
      .zoomTools button img {
          width: 1rem !important;
          height: 1rem !important;
      }
      .line{
          width: 1px;
          height: 22px;
          background-color: #FFFFFF29;
      
      }
      
    .viewContainer{
        position: relative;
        height: auto;
        width: 100%;
    }
    
    .viewContainer img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    .container-popup{
        position: absolute;
        /* width: 180px!important; */
        z-index: 10;
        right: 16px;
        top: 16px;
        /* background-color: rgba(77, 77, 77, 0.8); */
        border-radius: 6px;
    }
        .image-modal-container{
        background-color: transparent;
        width: 100%!important;
        /* height: 124px!important; */
        object-fit: cover!important;
        object-position: center;
        border-radius: 6px!important;
    }
    .header-slider-container{
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        /* height: 124px; */
        position: relative;
    }
    
    .active-hotspot{
        position: absolute;
        width : 32px;
        height : 32px;
        margin-left: -3px;
        margin-top: -2.5px;
    }
    
.circle {
    r: 12;
  }
  .helperTextContainerMobile{
    top: 4%;
    padding: 4px 8px 4px 8px;
    font-size: 12px!important;
    line-height: 16px;
    gap: 8px;
    height: 30px!important;
}

.helperTextContainerMobile img{
    width: 16px !important; 
    height: 16px !important;
} 
    .info-container p{
        margin: 0px!important;
        color: #FFF;
        
        text-align: center;
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        padding: 2px 5px!important;
        display: flex;
        align-items: center;
        justify-content: center;
  }
  .info-container p span{
    font-size: 11px!important;
  }
  
  .modal-image-viewer{
    width: 45px!important;
    height: 45px!important;
}
.image-modal .info-container{
    padding: 12px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  .image-modal .info-container p{
    font-size: 17px!important;
    margin-left: 10px!important;
  }
  .image-modal .info-container p span{
    font-size: 17px!important;
  }
  .image-modal .info-container svg{
    margin-right: 10px;
  }
}
@media screen and (min-device-width:300px) and (max-device-width: 440px){
    .container-popup{
        display: none;
    }
    .mobile-view-slider{
        display: block;
      }
    .slickListContainerItemcentered, .slickListContainerItemcentered-span{
        width: 18px !important;
        height: 18px !important;
    }
    .slickListContainerItemcentered, .slickListContainerItemcentered-span-hotspot{
        width: 18px !important;
        height: 18px !important;
    }
    .slickListContainerbeforeAfter img{
        object-fit: contain;
    }
    .slickListContainer img{
        object-fit: contain;
    }
    .slickListContainerDisable img{
        object-fit: contain;
    }
    .custom-card-body{
        font-size: 15px;
    }
    .custom-card-body h4{
        font-size: 15px;
        font-weight: 800;
    }
    .hotspot-toggle-view{
        width: 150px;
        height: auto;
        font-size: 13px;
    }
    .hotspot-toggle-view .dot{
        width: 10px;
        height: 10px;
    }
    .DentIcon__wrap{     
        width: 17px!important;
        height: 18px!important;
    }
    .DentIcon__label{
        margin-left: 15px;
        margin-top: -6px;    
        padding: 2px 4px;
        font-size: 5px;
        line-height: 5px;
    }
    .zoomTools{
        display: none;
    }
    .viewContainer{
        position: relative;
        height: auto;
        width: 100%;
    }
    
    .viewContainer img{
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    .helperTextContainerMobile{
        bottom: 4%;
        padding: 4px 8px 4px 8px;
        font-size: 12px;
        line-height: 16px;
        gap: 8px;
        height: 30px!important;

    }
    .helperTextContainerMobile img{
        width: 16px !important; 
        height: 16px !important;
    }
    .helperTextContainer{
        bottom: 4%;
        padding: 4px 8px 4px 8px;
        font-size: 12px;
        line-height: 16px;
        gap: 8px;
        height: 30px!important;
        
    }
    
    .helperTextContainer img{
        width: 16px !important; 
        height: 16px !important;
    }
    
    .helpTextContainerText{
        display: none;

    }
    .helpTextContainerTextMobile{
        display: block;
    }
    .interior-exterior-container{
      display: none;
    }
    .interior-exterior-container-mobile-view{
        display: block;
    }
    .container-popup{
        /* display: none; */
        /* background-color: #ebe7e3; */
        width: 100%;
    }
    
    
.active-hotspot{
    position: absolute;
    width : 35px;
    height : 35px;
    margin-left: -2px;
    margin-top: -2px;
}
.circle {
    r: 13;
  }
  .active-line-hotspot{
    display: block;
    position: absolute;
  }
  .info-container{
    background-color: #FFFFFF;
    color: #000000CC;
    height: 40px;
    position: relative;
  }
  .info-container p{
    color: rgba(0, 0, 0, 0.80);

    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* border-bottom-left-radius: 8px!important;
    border-bottom-right-radius: 8px!important; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .info-container p span{
    color: rgba(0, 0, 0, 0.60);
    text-align: center!important;
    font-family: Inter!important;
    font-size: 12px!important;
    font-style: normal!important;
    font-weight: 500!important;
    line-height: 20px!important;
  }
  .dropdown-container{
    bottom: 10px;
    top: auto;
    flex-direction: column-reverse;
  }
  .dropdown-url-name{
    margin: 0 0 1rem;
  }
  
.dropdown-icon-noactive{
    transform: rotate(-180deg);
    }
    .header-container{
        font-size: 11px;
    }
    .dropdown-url-heading{
        font-size: 12px;
    }
}
@media screen and (min-device-width:441px) and (max-device-width: 886px) {
    .info-container p{
        margin: 0px!important;
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        
        padding: 5px 4px!important;
        display: flex;
        align-items: center;
        justify-content: center;
  }
  .info-container p span{
    font-size: 12px!important;
  }
    .DentIcon__wrap{     
        /* width: 45px!important;
        height: 45px!important;
        margin-left: -1px; */
        width: 37px!important;
        height: 37px!important;
    }
    .DentIcon__label{
        margin-left: 30px;
    }
    .interior-exterior-container{
        top: 69%;
    }
    .hotspot-info-tag-left {
        padding: 6px 10px 6px 6px!important;
        width: 100%;
        height: 12px!important;
        padding: 6px 10px;
        border-radius: 5px;
        color: #FFFFFFCC;
        font-weight: 500;
        font-size: 10px!important;
        line-height: 12px!important;
        letter-spacing: 0em;
        text-align: center;
    } 
        .exterior-interior-tabs li{
        font-weight: 500;
        cursor: pointer;
        z-index: 1;
        color: #FFFFFF99;
        /* padding: 6px 13px; */
        transition: .2s all ease-in-out;
      }
      .zoomTools button img {
          width: 1rem !important;
          height: 1rem !important;
      }
      .line{
          width: 1px;
          height: 22px;
          background-color: #FFFFFF29;
      
      }
      .viewContainer{
          position: relative;
          height: auto;
          width: 100%;
      }
      
      .viewContainer img{
          height: 100%;
          width: 100%;
          object-fit: contain;
      }
      .container-popup{
          position: absolute;
          /* width: 220px!important; */
          z-index: 10;
          right: 16px;
          top: 16px;
          /* background-color: rgba(77, 77, 77, 0.8); */
          border-radius: 6px;
      }
      .image-modal-container{
        background-color: transparent;
        width: 100%!important;
        /* height: 140px!important; */
        object-fit: cover!important;
        object-position: center;
        border-radius: 6px!important;
    }
    .header-slider-container{
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        /* height: 140px; */
        position: relative;
    }
    .active-hotspot{
        position: absolute;
        width : 34px;
        height : 34px;
    }
    .circle {
        r: 15;
      }
    .modal-image-viewer{
        width: 50px!important;
        height: 50px!important;
    }
    .image-modal .info-container{
        padding: 13px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }
      .image-modal .info-container p{
        font-size: 18px!important;
        margin-left: 10px!important;
      }
      .image-modal .info-container p span{
        font-size: 18px!important;
      }
      .image-modal .info-container svg{
        margin-right: 10px;
      }
      .helperTextContainer{
        min-width: 130px!important;
      }
    
}
@media (max-width: 441px){
    .dropdown-container{
        bottom: 10px !important;
        top: auto;
        flex-direction: column-reverse;
      }
}

@media screen and (min-device-width:886px) and (max-device-width: 1100px) {
    .info-container p{
        margin: 0px!important;
        color: #FFF;
        
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        
        padding: 5px 4px!important;
        display: flex;
        align-items: center;
        justify-content: center;
  }
  .info-container p span{
    font-size: 12px!important;
  }
    .DentIcon__wrap{     
        /* width: 45px!important;
        height: 45px!important;
        margin-left: -1px; */
        width: 37px!important;
        height: 37px!important;
    }
    .DentIcon__label{
        margin-left: 30px;
    }
    .interior-exterior-container{
        top: 90%;
    }
    .hotspot-info-tag-left {
        padding: 6px 10px 6px 6px!important;
        width: 100%;
        height: 12px!important;
        padding: 6px 10px;
        border-radius: 5px;
        color: #FFFFFFCC;
        font-weight: 500;
        font-size: 10px!important;
        line-height: 12px!important;
        letter-spacing: 0em;
        text-align: center;
    } 
        .exterior-interior-tabs li{
        font-weight: 500;
        cursor: pointer;
        z-index: 1;
        color: #FFFFFF99;
        /* padding: 6px 13px; */
        transition: .2s all ease-in-out;
      }
      .zoomTools button img {
          width: 1rem !important;
          height: 1rem !important;
      }
      .line{
          width: 1px;
          height: 22px;
          background-color: #FFFFFF29;
      
      }
      .viewContainer{
          position: relative;
          height: auto;
          width: 100%;
      }
      
      .viewContainer img{
          height: 100%;
          width: 100%;
          object-fit: contain;
      }
      .container-popup{
          position: absolute;
          /* width: 220px!important; */
          z-index: 10;
          right: 16px;
          top: 16px;
          /* background-color: rgba(77, 77, 77, 0.8); */
          border-radius: 6px;
      }
      .image-modal-container{
        background-color: transparent;
        width: 100%!important;
        /* height: 140px!important; */
        object-fit: cover!important;
        object-position: center;
        border-radius: 6px!important;
    }
    .header-slider-container{
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        /* height: 140px; */
        position: relative;
    }
    .active-hotspot{
        position: absolute;
        width : 34px;
        height : 34px;
    }
    .circle {
        r: 15;
      }
    .modal-image-viewer{
        width: 50px!important;
        height: 50px!important;
    }
    .image-modal .info-container{
        padding: 13px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }
      .image-modal .info-container p{
        font-size: 18px!important;
        margin-left: 10px!important;
      }
      .image-modal .info-container p span{
        font-size: 18px!important;
      }
      .image-modal .info-container svg{
        margin-right: 10px;
      }
      .helperTextContainer{
        min-width: 130px!important;
      }
    
}
@media screen and (min-device-width:1100px) and (max-device-width: 1440px) {
    .DentIcon__label{
        margin-left: 32px;
        margin-top: -20px;
    }
    .DentIcon__wrap{     
        width: 35px!important;
        height: 35px!important;
    }
    .slickListContainerItemcentered, .slickListContainerItemcentered-span{
        width: 20px !important;
        height: 20px !important;
    }
    .slickListContainerItemcentered, .slickListContainerItemcentered-span-hotspot{
        width: 20px !important;
        height: 20px !important;
    }
    .container-popup{
        position: absolute;
        /* width: 250px!important; */
        z-index: 10;
        right: 16px;
        top: 16px;
        border-radius: 6px;
    }
    .image-modal-container{
        background-color: transparent;
        width: 100%!important;
        /* height: 150px!important; */
        object-fit: cover!important;
        object-position: center;
        border-radius: 6px!important;
    }
    
.header-slider-container{
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    /* height: 150px; */
    position: relative;
}
.active-hotspot{
    position: absolute;
    width : 32px;
    height : 32px;
}
.circle {
    r: 16;
  }
  /* .viewContainer{
    position: relative;
    height: auto;
    width: 100%;
} */

.viewContainer img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.info-container p{
    margin: 0px!important;
    color: #FFF;
    
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.info-container p span{
font-size: 13px!important;
}


.downloadButton {
    position: absolute;
    top: 10px; 
    right: 10px; /* Keep a slight offset from the right */
    z-index: 99999; /* Ensure it remains on top */
    background: #007bff;
    color: #fff;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .downloadButton:hover {
    background: #0056b3; /* Change color on hover */
  }
  

.helperTextContainer{
    min-width: 130px!important;
  }
}
/****Fade in****/
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}